<template>
  <div
    class="d-flex align-center justify-center"
    style="height: calc(100vh - 236px)"
  >
    <v-card class="pa-10 pt-0 rounded-xl elevation-0" :loading="loading">
      <div class="mt-10">
        <h3>Olá {{ user.name.split(" ")[0] }},</h3>
        <p class="text-16 font-weight-medium" v-if="loading || items.length">
          Antes de começar, escolha ou crie uma organização.
        </p>
        <p class="text-16 font-weight-medium" v-else>
          Você ainda não faz parte de nenhuma organização.
        </p>
      </div>
      <div class="w-full">
        <template v-if="loading || items.length">
          <vue-perfect-scrollbar
            :settings="{ suppressScrollX: true, wheelPropagation: false }"
            style="max-height: calc(50vh - 136px)"
          >
            <v-list>
              <v-list-item-group v-model="sOrganization">
                <v-list-item
                  v-for="item in items"
                  :key="item.id"
                  :value="item.id"
                >
                  <v-list-item-avatar size="40">
                    <base-avatar
                      :src="item.photo"
                      :seed="item.id"
                      :size="40"
                      avatarStyle="shapes"
                      color="secondary lighten-2"
                    />
                  </v-list-item-avatar>
                  <v-list-item-title>{{ item.name }}</v-list-item-title>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </vue-perfect-scrollbar>
          <!-- <div class="py-4 d-flex align-center">
            <v-divider></v-divider>
            <span class="text-center text--secondary">ou</span>
            <v-divider></v-divider>
          </div> -->
        </template>
        <!-- <v-btn
          :disabled="loading || !!sOrganization.length"
          :to="{ name: 'admin.create' }"
          block
          large
          color="primary"
        >
          <div class="d-flex flex-column">Criar Organização</div>
        </v-btn> -->
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    loading: false,
    sOrganization: "",
    items: [],
  }),
  methods: {
    ...mapActions(["changeThemeLoadingState"]),
    ...mapActions("organization", [
      "getAllOrganizations",
      "selectOrganization",
    ]),

    async getOrganizations() {
      try {
        this.changeThemeLoadingState(true);
        this.loading = true;

        const organizations = await this.getAllOrganizations();

        this.items = organizations
        // .slice(0, 5);
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
        this.changeThemeLoadingState(false);
      }
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    ...mapGetters("auth", ["user"]),
  },
  watch: {
    sOrganization(newValue) {
      if (newValue) {
        this.selectOrganization(newValue);
        this.$router.replace({
          name: "admin.dashboard",
        });
      }
    },
  },
  async mounted() {
    await this.getOrganizations();
    if (this.selectedOrganization) {
      this.sOrganization = this.selectedOrganization.id;
    }
  },
};
</script>

<style></style>

<template>
  <v-card class="rounded-lg pa-4" elevation="0">
    <h6>Redes Sociais</h6>
    <div class="d-flex flex-column gap-2">
      <div
        v-for="(sN, i) in socialNetworks"
        :key="i + sN.type"
        class="d-flex align-center gap-1"
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on" :disabled="loading">
              <span
                v-if="socialNetworksItens[sN.type]?.image"
                v-html="socialNetworksItens[sN.type]?.image"
                class="mr-1"
              ></span>
              <v-icon v-else-if="socialNetworksItens[sN.type]?.icon" small left>
                {{ socialNetworksItens[sN.type]?.icon }}
              </v-icon>
              {{ socialNetworksItens[sN.type]?.name }}
            </v-btn>
          </template>
          <v-card class="d-flex flex-column gap-1">
            <v-btn
              v-for="(snI, index) in socialNetworksItens"
              :key="index"
              elevation="0"
              block
              text
              @click="selectSN(i, snI.id)"
            >
              <span v-if="snI.image" v-html="snI?.image" class="mr-1"></span>
              <v-icon v-else-if="snI.icon" small left>
                {{ snI.icon }}
              </v-icon>
              {{ snI.name }}
            </v-btn>
          </v-card>
        </v-menu>
        <v-text-field
          v-model="sN.url"
          :label="socialNetworksItens[sN.type]?.name"
          :prefix="socialNetworksItens[sN.type]?.prefix"
          :placeholder="socialNetworksItens[sN.type]?.placeholder"
          outlined
          hide-details
          dense
          :disabled="loading"
          append-outer-icon="mdi-close"
          @click:append-outer="socialNetworks.splice(i, 1)"
        ></v-text-field>
      </div>
      <v-btn @click="addSocialNetwork" text small :disabled="loading">
        <v-icon small left> mdi-plus </v-icon>
        Adicionar Rede Social
      </v-btn>

      <v-alert v-if="error" type="error" class="mt-2" border="left" text>
        {{ error }}
      </v-alert>
      <v-btn
        color="success"
        @click="save"
        small
        :disabled="!hasDiference"
        :loading="loading"
      >
        Salvar
      </v-btn>
    </div>
  </v-card>
</template>

<script>
import ORGANIZATION from "@/services/admin/organization";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      loading: false,
      error: false,
      socialNetworks: structuredClone(this.value || []),
      socialNetworksItens: {
        ig: {
          id: "ig",
          name: "Instagram",
          icon: "mdi-instagram",
          urlFormatter: (url) => `https://instagram.com/${url}`,
          prefix: "@",
          placeholder: "Digite o nome de usuário",
        },
        fb: {
          id: "fb",
          name: "Facebook",
          icon: "mdi-facebook",
          urlFormatter: (url) => `https://facebook.com/${url}`,
          prefix: "@",
          placeholder: "Digite o nome de usuário",
        },
        tw: {
          id: "tw",
          name: "Twitter",
          icon: "mdi-twitter",
          urlFormatter: (url) => `https://twitter.com/${url}`,
          prefix: "@",
          placeholder: "Digite o nome de usuário",
        },
        yt: {
          id: "yt",
          name: "Youtube",
          icon: "mdi-youtube",
          urlFormatter: (url) => url,
          prefix: "",
          placeholder: "Digite o link do canal",
        },
        tt: {
          id: "tt",
          name: "TikTok",
          image: `<svg fill="currentColor" width="16px" height="16px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" xml:space="preserve"><path d="M19.589 6.686a4.793 4.793 0 0 1-3.77-4.245V2h-3.445v13.672a2.896 2.896 0 0 1-5.201 1.743l-.002-.001.002.001a2.895 2.895 0 0 1 3.183-4.51v-3.5a6.329 6.329 0 0 0-5.394 10.692 6.33 6.33 0 0 0 10.857-4.424V8.687a8.182 8.182 0 0 0 4.773 1.526V6.79a4.831 4.831 0 0 1-1.003-.104z"/></svg>`,
          urlFormatter: (url) => `https://tiktok.com/@${url}`,
          prefix: "@",
          placeholder: "Digite o nome de usuário",
        },
      },
    };
  },
  methods: {
    async save() {
      try {
        this.loading = true;
        this.error = false;
        await ORGANIZATION.update(this.selectedOrganization.id, {
          socialNetworks: this.formattedSocialNetworks,
        });
        this.$emit("refresh");
      } catch (e) {
        this.error = e.message || "Erro ao salvar redes sociais";
      } finally {
        this.loading = false;
      }
    },
    selectSN(index, id) {
      this.$set(this.socialNetworks, index, {
        type: id,
        url: "",
      });
    },
    addSocialNetwork() {
      this.socialNetworks.push({
        type: "ig",
        url: "",
      });
    },
  },
  computed: {
    ...mapGetters("organization", ["selectedOrganization"]),
    formattedSocialNetworks() {
      return this.socialNetworks.filter((sN) => sN.url);
    },
    hasDiference() {
      return (
        JSON.stringify(this.formattedSocialNetworks) !==
        JSON.stringify(this.value)
      );
    },
  },
  watch: {
    value: {
      handler() {
        this.socialNetworks = structuredClone(this.value || []);
      },
      deep: true,
    },
  },
  props: {
    value: {
      type: Array || null,
    },
  },
};
</script>

<style></style>

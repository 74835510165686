<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="700"
      :persistent="loading"
      contentClass="rounded-xl"
      :overlay-opacity="0.9"
      scrollable
      eager
    >
      <!-- Confirm ticket group and ticket block to finish sale -->
      <v-card v-if="ticketGroup && ticketBlock" class="overflow-hidden">
        <table-selector
          containerId="table-selector"
          v-model="selectedTable"
          class="rounded-b-0 elevation-0"
          :table-map="tableMapComputed"
          :party="party"
          :filter-group="ticketGroup?.tableGroupId"
          colorMode="available"
          multiple
          ref="tableSelector"
        />

        <!-- Actions -->
        <v-card-actions class="mt-2">
          <v-btn icon @click="close" :disabled="loading">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="!selectedTable || selectedTable.length === 0"
            @click="confirmSelect"
          >
            Selecionar
            <template v-if="selectedTable">
              <span v-if="selectedTable.length == 1">
                {{ selectedTable[0]?.group?.name }} •
                {{ selectedTable[0]?.name }}
              </span>
              <span v-else-if="selectedTable.length > 1">
                • {{ selectedTable.length }}
                {{ selectedTable[0]?.group?.name }}s
              </span>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import TableSelector from "@/components/global/partyTable/TableSelector.vue";

export default {
  components: {
    TableSelector,
  },
  data: () => ({
    loading: false,
    error: false,
    dialog: false,
    ticketBlock: false,
    ticketGroup: false,
    selectedTable: [],
    tableMapComputed: null,
  }),
  methods: {
    confirmSelect() {
      if (!this.selectedTable) {
        this.error = true;
        return;
      }
      this.selectedTable.forEach((table) => {
        this.$emit("select", {
          ticketGroup: this.ticketGroup,
          ticketBlock: this.ticketBlock,
          table: table,
        });
      });
      this.close();
    },
    open({ ticketBlock, ticketGroup }) {
      this.ticketBlock = ticketBlock;
      this.ticketGroup = ticketGroup;
      this.selectedTable = null;
      this.error = false;
      this.dialog = true;
      this.$nextTick(() => {
        this.$refs.tableSelector?.init();
      });
    },
    close() {
      if (this.loading) return;
      this.dialog = false;
      this.selectedTable = null;
    },
    computeTableMap(tableMap, cart = {}) {
      const tablesCart = Object.values(cart)
        .map((item) => item.tables)
        .flat();

      var tableMap = structuredClone(tableMap);

      tableMap.Groups = tableMap.Groups.map((group) => {
        group.Tables = group.Tables.map((table) => {
          const tableCart = tablesCart.find((t) => t.id === table.id);

          if (tableCart) table.status = "inCart";

          return table;
        });
        return group;
      });

      this.tableMapComputed = tableMap;
    },
  },

  watch: {
    tableMap: {
      handler(tableMap) {
        this.computeTableMap(tableMap, this.cart);
      },
      deep: true,
    },
    cart: {
      handler(cart) {
        this.computeTableMap(this.tableMap, cart);
      },
      deep: true,
    },
  },

  props: {
    party: {
      type: Object,
      required: true,
    },
    cart: {
      type: Object,
      required: true,
    },
    tableMap: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.computeTableMap(this.tableMap, this.cart);
    this.$root.$on("select-table", this.open);
  },
};
</script>

<style></style>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"form-sell-ticket"}},[(_vm.loading)?_c('v-progress-linear',{staticClass:"mb-4",attrs:{"indeterminate":""}}):_vm._e(),(_vm.ticketGroups.length)?_c('ColsOrganizer',{attrs:{"cols":"1","sm":"2","lg":"3","items":_vm.ticketGroupsMapped,"itemKey":"id","gap":1},scopedSlots:_vm._u([{key:"default",fn:function({ item: tg }){return [_c('v-card',{staticClass:"pa-4 rounded-lg",attrs:{"outlined":""}},[_c('div',[(tg.tableGroupId)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"float-right",attrs:{"small":""}},'v-chip',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-table-chair")]),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(tg.TableGroup.name)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(" Lugares marcados ")])]):_vm._e(),_c('h5',{staticClass:"mb-1"},[_vm._v(_vm._s(tg.name))]),_c('DescriptionWrap',{attrs:{"description":tg.description,"lines":2.5}}),(tg.tableGroupId && tg.TableGroup.capacity > 1)?_c('v-alert',{attrs:{"type":"info","color":"primary","dense":"","text":""}},[_vm._v(" Compras neste setor dão direito "),_c('b',{staticClass:"font-weight-bold"},[_vm._v(" "+_vm._s(tg.TableGroup.capacity)+" ingressos ")])]):_vm._e()],1),_vm._l((tg.TicketBlock),function(tb){return _c('v-card',{key:tb.id,attrs:{"disabled":!tb.startDate || new Date(tb.startDate) > new Date(),"elevation":"0"}},[_c('v-alert',{staticClass:"mt-2 mb-2",attrs:{"color":tb.startDate && new Date(tb.startDate) < new Date()
                ? 'primary'
                : 'secondary',"outlined":"","dense":"","text":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-1 gap-2 flex-wrap"},[_c('div',[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(tb.name))]),_c('div',{staticClass:"d-flex align-center gap-2"},[_c('b',{class:{
                      'text-18': !tb.promotion,
                      'text--disabled': tb.promotion,
                      'text-decoration-line-through': tb.promotion,
                    }},[_vm._v(" "+_vm._s(_vm._f("currency")(tb.price,true))+" ")]),(
                      !!tb.fee &&
                      tb.startDate &&
                      new Date(tb.startDate) <= new Date()
                    )?_c('small',{staticClass:"text--disabled"},[_vm._v(" + "+_vm._s(_vm._f("currency")(tb.fee,true))+" de taxa ")]):_vm._e(),(tb.promotion)?[_c('b',{staticClass:"text-18"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.calculateTicketPricePromotion(tb, tb.promotion),true))+" ")]),_c('v-chip',{attrs:{"label":"","color":"success","small":""}},[_vm._v(" -"),(tb.promotion.data.discountType == 'percentage')?[_vm._v(" "+_vm._s(tb.promotion.data.discount + "%")+" ")]:[_vm._v(" "+_vm._s(_vm._f("currency")(tb.promotion.data.discount,true))+" ")]],2)]:_vm._e(),(tb.TicketBlockMembership.length)?[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-card-account-details-star ")])]:_vm._e()],2)]),(!tb.startDate || new Date(tb.startDate) > new Date())?_c('div',{staticClass:"text-end"},[_c('small',[_vm._v(" Em breve ")])]):_vm._e(),(tg.TableGroup)?[(_vm.maxTickets === 1 || !_vm.cart[tb.id]?.quantity)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading === tb.id,"disabled":!!_vm.maxTickets &&
                    (_vm.ticketsPerGroup[tg.id]?.total >=
                      Math.min(_vm.maxTickets, tg.maxTickets) ||
                      _vm.partyTotal >= _vm.maxTickets)},on:{"click":function($event){return _vm.selectTable(tb, tg)}}},[_vm._v(" Selecionar Lugar ")]):_c('v-card',{staticClass:"d-flex align-center gap-2 rounded-pill pa-1 align-self-center",attrs:{"outlined":""}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.removeItem(tb.id)}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('b',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.cart[tb.id].quantity)+" ")]),_c('v-btn',{attrs:{"icon":"","small":"","disabled":!!_vm.maxTickets &&
                      (_vm.ticketsPerGroup[tg.id]?.total >=
                        Math.min(_vm.maxTickets, tg.maxTickets) ||
                        _vm.partyTotal >= _vm.maxTickets)},on:{"click":function($event){return _vm.selectTable(tb, tg)}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]:_c('div',[[(_vm.party.maxTickets === 1 || !_vm.cart[tb.id]?.quantity)?_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading === tb.id,"disabled":!!_vm.party.maxTickets &&
                      (_vm.ticketsPerGroup[tg.id]?.total >=
                        Math.min(_vm.party.maxTickets, tg.maxTickets) ||
                        _vm.partyTotal >= _vm.party.maxTickets)},on:{"click":function($event){return _vm.buyTicket({ ticketGroup: tg, ticketBlock: tb })}}},[_vm._v(" "+_vm._s(_vm.party.maxTickets === 1 ? "Comprar" : "Adicionar")+" ")]):_c('v-card',{staticClass:"d-flex align-center gap-2 rounded-pill pa-1 align-self-center",attrs:{"outlined":""}},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.cart[tb.id].quantity--}}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('b',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.cart[tb.id].quantity)+" ")]),_c('v-btn',{attrs:{"icon":"","small":"","disabled":!!_vm.party.maxTickets &&
                        (_vm.ticketsPerGroup[tg.id]?.total >=
                          Math.min(_vm.party.maxTickets, tg.maxTickets) ||
                          _vm.partyTotal >= _vm.party.maxTickets)},on:{"click":function($event){return _vm.buyTicket({ ticketGroup: tg, ticketBlock: tb })}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)]],2)],2)]),_c('div',{staticClass:"d-flex"})],1)}),(tg.KitList.length)?[_c('b',{staticClass:"mt-4"},[_vm._v(" Pacotes ")])]:_vm._e(),_vm._l((tg.KitList),function(kit){return _c('div',{key:kit.id,staticClass:"card-container mb-5",class:{ dark: _vm.$vuetify.theme.dark }},[_c('v-card',{staticStyle:{"z-index":"3"},attrs:{"disabled":!kit.startDate || new Date(kit.startDate) > new Date(),"elevation":"0"}},[_c('v-alert',{staticClass:"mt-2 mb-0",attrs:{"color":kit.startDate && new Date(kit.startDate) < new Date()
                  ? 'primary'
                  : 'secondary',"outlined":"","dense":"","text":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center mb-1 gap-2 flex-wrap"},[_c('div',[_c('h6',{staticClass:"mb-0"},[_vm._v(_vm._s(kit.name))]),_c('div',{staticClass:"d-flex align-center gap-2"},[_c('b',{staticClass:"text-18"},[_vm._v(" "+_vm._s(_vm._f("currency")(kit.price,true))+" ")]),(
                        !!kit.fee &&
                        kit.startDate &&
                        new Date(kit.startDate) <= new Date()
                      )?_c('small',{staticClass:"text--disabled"},[_vm._v(" + "+_vm._s(_vm._f("currency")(kit.fee,true))+" de taxa ")]):_vm._e()])]),(
                    !kit.startDate || new Date(kit.startDate) > new Date()
                  )?_c('div',{staticClass:"text-end"},[_c('small',[_vm._v(" Em breve ")])]):_c('div',[[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.buyKit({ ticketGroup: tg, kit: kit })}}},[_vm._v(" Comprar ")])]],2)])]),_c('div',{staticClass:"d-flex"})],1),_c('div',{staticClass:"stack-layer"}),_c('div',{staticClass:"stack-layer"})],1)}),(!!_vm.party.maxTickets)?_c('p',{staticClass:"mb-0 text-center text-12 text--secondary"},[_vm._v(" Limite de "+_vm._s(Math.min(_vm.party.maxTickets, tg.maxTickets))+" ingresso"+_vm._s(Math.min(_vm.party.maxTickets, tg.maxTickets) != 1 ? "s" : "")+" por pessoa para esse setor. ")]):_vm._e()],2)]}}],null,false,628302131)}):(!_vm.loading)?[_c('div',{staticClass:"text-center"},[_c('v-avatar',{attrs:{"color":"grey lighten-4","size":"50"}},[_c('v-icon',{staticClass:"display-1 grey darken-1",attrs:{"x-large":""}},[_vm._v(" mdi-ticket ")])],1),_c('p',{staticClass:"text--disabled mt-2"},[_vm._v(" Não há ingressos disponíveis para esse evento ")])],1)]:_vm._e(),_c('v-alert',{staticClass:"mt-4",attrs:{"type":"info","text":""}},[_c('div',{staticClass:"d-flex justify-space-between align-center flex-wrap gap-y-2"},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(" Compartilhe o link do evento para que outras pessoas possam comprar ingressos ")])]),_c('v-btn',{attrs:{"small":""},on:{"click":_vm.share}},[_vm._v(" Compartilhar ")])],1)]),(_vm.hasPermission(16))?_c('v-alert',{staticClass:"mt-4",attrs:{"type":"info"}},[_c('div',{staticClass:"d-flex justify-space-between align-center flex-wrap gap-y-2"},[_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(" Você pode gerenciar os ingressos disponíveis para venda ")])]),_c('v-btn',{attrs:{"small":""},on:{"click":function($event){return _vm.$router.push({
            name: 'admin.party.management',
            params: { partyId: _vm.party.id },
            hash: '#tickets',
          })}}},[_vm._v(" Gerenciar ")])],1)]):_vm._e(),_c('div',{staticClass:"d-flex justify-space-between align-center flex-wrap gap-y-2"},[_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","disabled":_vm.loading,"small":""},on:{"click":function($event){return _vm.$emit('prev')}}},[_c('v-icon',[_vm._v("mdi-chevron-left")]),_vm._v(" voltar ")],1),_c('v-btn',{staticClass:"mt-2",attrs:{"text":"","disabled":_vm.loading || _vm.cartTotal.quantity === 0,"small":""},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" próximo"),_c('v-icon',[_vm._v("mdi-chevron-right")])],1)],1),_c('v-slide-y-reverse-transition',[(_vm.cartTotal.quantity)?_c('div',{staticClass:"d-flex justify-center pa-3",staticStyle:{"position":"fixed","bottom":"0px","left":"0px","right":"0px","z-index":"10"}},[_c('v-card',{staticClass:"d-flex align-center pa-3 gap-4 flex-grow-",attrs:{"elevation":"24","rounded":"xl","light":""}},[_c('v-icon',[_vm._v("mdi-cart")]),_c('span',{staticClass:"text-16 font-weight-medium flex-grow-1"},[_vm._v(" "+_vm._s(_vm.cartTotal.quantity)+" ingresso"+_vm._s(_vm.cartTotal.quantity != 1 ? "s" : "")+" • "+_vm._s(_vm._f("currency")(_vm.cartTotal.total,true))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" Finalizar Compra ")])],1)],1):_vm._e()]),_c('buy-ticket',{attrs:{"party":_vm.party}}),_c('partyQrCode',{ref:"qrCode"}),(_vm.tableMap)?_c('select-table',{attrs:{"party":_vm.partyMap,"cart":_vm.cart,"table-map":_vm.tableMap},on:{"select":function($event){return _vm.buyTicket($event)}}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }
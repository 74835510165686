<template>
  <v-dialog
    v-model="dialog"
    max-width="500px"
    overlay-opacity="0.9"
    @click:outside="close"
  >
    <v-card>
      <v-card-title class="headline"> Informações do Cliente </v-card-title>
      <v-card-text class="pb-1 pt-4">
        <v-form
          v-model="valid"
          ref="transferForm"
          class="mb-4"
          @submit="submit"
        >
          <v-text-field
            label="Nome"
            v-model="user.name"
            :rules="nameRules"
            dense
            outlined
          />
          <v-text-field
            label="Email"
            v-model="user.email"
            :rules="emailRules"
            dense
            outlined
          />
          <v-text-field-simplemask
            v-model="user.document"
            label="CPF"
            v-bind:properties="{
              prefix: '',
              suffix: '',
              outlined: true,
              placeholder: '999.999.999-99',
              type: 'tel',
              rules: cpfRules,
              dense: true,
              hideDetails: 'auto',
            }"
            v-bind:options="{
              inputMask: '###.###.###-##',
              outputMask: '###.###.###-##',
              empty: '',
              applyAfter: false,
              alphanumeric: false,
              lowerCase: false,
            }"
          />
        </v-form>
        <!-- <v-alert type="info" class="mb-0" border="left">
          Atenção: apos reembolsar um ingresso não será possível utilizar,
          transferir ou reembolsar novamente.
        </v-alert> -->
      </v-card-text>
      <v-card-actions class="d-flex flex-column">
        <v-btn
          color="primary"
          @click="submit"
          block
          :disabled="!valid"
          class="mb-2"
        >
          Finalizar
        </v-btn>
        <v-btn color="primary" text @click="close" block class="ml-0">
          Fechar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validateCpf from "@/utils/validate-cpf";
export default {
  data: () => ({
    dialog: false,
    user: {
      name: "",
      document: "",
      email: "",
    },
    valid: false,
    cpfRules: [
      (v) => !!v || "CPF é obrigatório",
      (v) => validateCpf(v) || "CPF inválido",
    ],
    emailRules: [
      (v) => !!v || "Email é obrigatório",
      (v) =>
        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
          v
        ) || "Email inválido",
    ],
    nameRules: [
      (v) => !!v || "Nome é obrigatório",
      (v) => v.length >= 3 || "Nome deve ter no mínimo 3 caracteres",
      (v) =>
        v.trim().split(" ").length >= 2 || "Nome deve ter no mínimo 2 nomes",
    ],
  }),
  methods: {
    async submit() {
      this.$emit("submit", this.user);
      this.close();
    },
    open() {
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.user = {
        name: "",
        document: "",
        email: "",
      };
    },
  },
  mounted() {
    this.$root.$on("online-sell-modal", this.open);
  },
};
</script>

<style></style>

<template>
  <div>
    <v-data-table
      dense
      :headers="headers"
      :items="sales"
      :items-per-page="sales.length"
      hide-default-footer
      sort-by="createdAt"
    >
      <template v-slot:item.name="{ item }">
        {{ item.TicketGroup.name }} -
        {{ item.name }}
      </template>
      <template v-slot:item.tickets.length="{ item }">
        {{ item.tickets.length }} ingresso{{
          item.tickets.length > 1 ? "s" : ""
        }}
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  computed: {
    headers() {
      return [
        {
          text: "Lote",
          value: "name",
          sortable: false,
        },
        {
          text: "Quantidade",
          value: "tickets.length",
          sortable: false,
        },
      ];
    },
    reducedByTicketBlock() {
      const reduce = this.sales.reduce((acc, sale) => {
        const ticketBlock = `${sale.ticketGroup}-${sale.ticketBlock}`;
        acc[ticketBlock] = acc[ticketBlock] || 0;
        acc[ticketBlock] += 1;
        return acc;
      }, {});

      return Object.entries(reduce).map(([name, qty]) => ({ name, qty }));
    },
  },
  props: {
    sales: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style></style>
